/**
 * tenant module
 */
export default {
  'views/tenants': () => import('@/views/tenants/index.vue'),
  'views/tenants/[id]': () => import('@/views/tenants/[id]/index.vue'),
  'views/tenants/[id]/overview': () => import('@/views/tenants/[id]/overview/index.vue'),
  'views/tenants/[id]/template/wireless': () =>
    import('@/views/tenants/[id]/template/wireless/index.vue'),
  'views/tenants/[id]/topology': () => import('@/views/tenants/[id]/topology/index.vue'),
  'views/tenants/[id]/tunnel': () => import('@/views/tenants/[id]/tunnel/index.vue'),
  'views/tenants/[id]/tag': () => import('@/views/tenants/[id]/tag/index.vue'),
  'views/tenants/[id]/user': () => import('@/views/tenants/[id]/user/index.vue'),
  'views/tenants/[id]/license': () => import('@/views/tenants/[id]/license/index.vue'),
  'views/tenants/[id]/device': () => import('@/views/tenants/[id]/device/index.vue'),
};
