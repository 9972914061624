export const LOGIN_NAME = 'Login';

export const SETUP2FA_NAME = 'Setup2FA';

export const VERIFY2FA_NAME = 'Verify2FA';

export const REDIRECT_NAME = 'Redirect';

export const PARENT_LAYOUT_NAME = 'ParentLayout';

export const PAGE_NOT_FOUND_NAME = 'PageNotFound';

// 路由白名单

export const whiteNameList = [
  LOGIN_NAME,
  'icons',
  'error',
  'error-404',
  SETUP2FA_NAME,
  VERIFY2FA_NAME,
] as const; // no redirect whitelist

export type WhiteNameList = typeof whiteNameList;

export type WhiteName = (typeof whiteNameList)[number];
