import { type RouteRecordRaw } from 'vue-router';
import { LOGIN_NAME, VERIFY2FA_NAME, SETUP2FA_NAME } from '@/router/constant';
import { t } from '@/hooks/useI18n';
const urlParams = new URLSearchParams(window.location.href);
/**
 * layout布局之外的路由
 */
export const LoginRoute: RouteRecordRaw = {
  path: '/login',
  name: LOGIN_NAME,
  component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
  meta: {
    title: t('common.login'),
  },
};

export const Verify2FARoute: RouteRecordRaw = {
  path: '/2fa/verify',
  name: VERIFY2FA_NAME,
  component: () => import('@/views/2fa/verify.vue'),
  meta: {
    title: t('common.verify2fa'),
  },
};

export const Setup2FARoute: RouteRecordRaw = {
  path: '/2fa',
  name: SETUP2FA_NAME,
  component: () => import(/* webpackChunkName: "setup2fa" */ '@/views/2fa/index.vue'),
  meta: {
    title: t('common.setup2fa'),
  },
};

export const ConsoleRoute: RouteRecordRaw = {
  path: '/node/console',
  name: `web-console`,
  component: () =>
    import(
      /* webpackChunkName: "node-web-console" */ '@/views/tenants/components/shellConsole.vue'
    ),

  meta: {
    title: urlParams.get('deviceName') + ' - Web Console',
  },
};

export default [LoginRoute, Verify2FARoute, Setup2FARoute, ConsoleRoute];
